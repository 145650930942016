export const ITEMS_PER_PAGE = {
  10: 10,
  15: 15,
  20: 20
};

export const ITEMS_FOR_CASOUSEL = 5;

export const REVIEW_PER_PAGE = 10;

export const PROFILE_REVIEW_PER_PAGE = 10;

export const ITEMS_PER_PAGE_CUSTOMER = {
  '10': {
    'countPerPage': 10,
    'isInfinityScroll': false,
  },
  '20': {
    'countPerPage': 20,
    'isInfinityScroll': false,
  },
  'infinity': {
    'countPerPage': 10,
    'isInfinityScroll': true,
  }
};