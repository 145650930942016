import {
  APPLY_FILTERS,
  APPLY_SELECTS_VALUES_TO_FILTERS,
  RESET_FILTERS,
  SET_ALL_FILTER_VALUE,
  SET_FILTER,
  SET_HISTOGRAM_FILTER,
  SET_ZIP_FILTER,
  SET_SEARCH_STRING,
  SET_SELECT_OPTION,
  SET_SORT_VALUE,
  SET_ITEMS_PER_PAGE_CUSTOMER,
  SET_PAGE,
  SET_INFINITY_SCROLL,
} from './companyFilterConstants';

import { companySorts } from '../../app/constants/sorts';
import { filterNames } from '../../app/constants/filters';
import { ITEMS_PER_PAGE_CUSTOMER } from "../../app/constants/pagination";

const initialState = {
  sort: companySorts.POPULAR.value,
  countPerPage: ITEMS_PER_PAGE_CUSTOMER['10'].countPerPage,
  page: 1,
  isInfinityScroll: ITEMS_PER_PAGE_CUSTOMER['10'].isInfinityScroll,
  search: '',
  filter: {
    [filterNames.KANTONES]: [],
    [filterNames.CITY]: [],
    [filterNames.ZIP]: [],
    [filterNames.SOFTWARE]: [],
    [filterNames.COMMUNICATION_PREFERENCES]: '',
    [filterNames.BASIS]: false,
    [filterNames.SERVICES]: [],
    [filterNames.INDUSTRIES]: [],
    [filterNames.SPECIAL_OFFER]: false,
    [filterNames.LANGUAGES]: [],
    [filterNames.MEMBER]: [],
    [filterNames.HOURLY_RATE]: [],
    [filterNames.TAX_REPRESENTATION]: false,
  },
  selects: {
    [filterNames.KANTONES]: null,
    [filterNames.CITY]: null,
    [filterNames.ZIP]: null,
    [filterNames.COMMUNICATION_PREFERENCES]: '',
    [filterNames.SOFTWARE]: [],
    [filterNames.SERVICES]: [],
    [filterNames.INDUSTRIES]: [],
    [filterNames.HOURLY_RATE]: null,
    [filterNames.BASIS]: false,
    [filterNames.SPECIAL_OFFER]: false,
  }
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {

    case SET_FILTER: {
      const filterData = state.filter[payload.filterName];

      const filterValue = () => {
        switch(true) {
          case [filterNames.INDUSTRIES].includes(payload.filterName):
            return (
              filterData.includes(payload.value)
              ?  filterData.filter(i => i !== payload.value)
              : [ payload.value ]
            );

          case [filterNames.COMMUNICATION_PREFERENCES].includes(payload.filterName):
            return (
              filterData.includes(payload.value)
              ? payload.value = ''
              : payload.value
            );

          case [filterNames.BASIS, filterNames.SPECIAL_OFFER, filterNames.TAX_REPRESENTATION].includes(payload.filterName):
            return (
              payload.value
            );

          default:
            return (
              filterData.includes(payload.value)
              ?  filterData.filter(i => i !== payload.value)
              : [ ...filterData, payload.value ]
            );
        }
      }
      return {
        ...state,
        filter: {
          ...state.filter,
          [payload.filterName]: filterValue()
        }
      }
    }
    case SET_HISTOGRAM_FILTER: {
      return {
        ...state,
        filter: {
          ...state.filter,
          [payload.filterName]: payload.value
        }
      }
    }
    case SET_ZIP_FILTER: {
      return {
        ...state,
        filter: {
          ...state.filter,
          [filterNames.ZIP]: payload
        }
      }
    }
    case SET_SELECT_OPTION: {
      return {
        ...state,
        selects: {
          ...state.selects,
          ...payload
          //[payload.filterName]: payload.value
        }
      }
    }
    case SET_ALL_FILTER_VALUE: {
      return {
        ...state,
        filter: {
          ...state.filter,
          [payload.filterName]: payload.values,
        }
      }
    }
    case APPLY_FILTERS: {
      return {
        ...state,
        filter: payload
      }
    }
    case APPLY_SELECTS_VALUES_TO_FILTERS: {
      return {
        ...state,
        filter: {
          ...state.filter,
          ...payload
        },
        selects: initialState.selects
      }
    }
    case SET_SEARCH_STRING: {
      return {
        ...state,
        search: payload
      }
    }
    case SET_SORT_VALUE: {
      return {
        ...state,
        sort: payload
      }
    }
    case RESET_FILTERS: {
      return {
        ...state,
        filter: {
          ...initialState.filter
        }
      }
    }
    case SET_ITEMS_PER_PAGE_CUSTOMER: {
      return {
        ...state,
        countPerPage: payload
      }
    }
    case SET_PAGE: {
      return {
        ...state,
        page: payload
      }
    }
    case SET_INFINITY_SCROLL: {
      return {
        ...state,
        isInfinityScroll: payload
      }
    }
    default:
      return state;
  }

}
